<template>
    <BaseSection>
        <div class="card mt-3 text-center">
            <div class="card-body">
                <h1>{{ status }}</h1>
                <p>{{ message }}</p>
                <RouterLink class="btn btn-primary" to="/auth/signin" tag="button">
                    Click here to sign in!
                </RouterLink>
            </div>
        </div>
    </BaseSection>
</template>

<script>
import { BaseSection } from '@/components'

export default {
    name: 'SignUpConfirmation',

    components: { BaseSection },

    //logic if confirmation is success or failed
    mounted() {
        const url = `/auth/activate/${this.$route.params.code}`
        //const url = `/auth/confirm?code=${this.$route.query.code}`

        this.$store.state.backend
            .post(url)
            .then((ret) => {
                this.status = ret.data.status
                this.message = ret.data.message
            })
            .catch((error) => alert('Error (mounted):', error))
    },

    data() {
        return {
            status: '',
            message: '',
        }
    },
}
</script>
